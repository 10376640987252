/* eslint-disable import/order */
import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'

import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:undefined,
    dsn:"https:\u002F\u002Fee0b4a75dad44a1a84d2026d7945b983@o408639.ingest.sentry.io\u002F4504828989800448",
    environment:"staging",
    beforeSend:(event) => {
  /**
   * SentryのAlaramでエラーをとっているため、直接エラーをS3に保存します。
   *
   * ここではAxiosを普通には呼べないのと、無理して呼んでもLoadingの処理も対応する必要があるのでfetch API使ってます。
   * 待つこともError対応もする必要はありません。単純にログ出力しているだけなので失敗したらそのまま終わらせてください。
   *
   * @param event
   * @returns
   */
  const sendErrorLog = (event) => {
    if (!event || !process.env.ERROR_LOG_URL) return;

    const token = localStorage.getItem("auth._token.bamidp");
    if (!token) return;

    fetch(process.env.ERROR_LOG_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token },

      body: JSON.stringify(event) }).
    catch();
  };

  /**
   * 画面名を拡張領域にセットします。
   * @param event
   * @returns
   */
  const setScreenName = (event) => {
    const tabTitle = document.title.split("｜")[0].trimEnd();
    event.user = {
      ...event.user,
      ...{ screen_name: tabTitle || event.request?.url || "-" } };

    return event;
  };

  /**
   * APIの戻りによるエラーか？を判定します。
   * @param event
   * @returns
   */
  const isResponse = (event) => {
    const { Error: err } = event.contexts || {};
    const config = err?.config;
    if (!config || !config.url) return false;

    const response = err?.response;
    return response && response.data;
  };

  /**
   * Sentryに送らないレスポンスステータスか？を判定します。
   * @param event
   * @returns
   */
  const isCancel = (event) => {
    const { Error: err } = event.contexts || {};
    const config = err?.config;
    if (!config || !config.url) return false;

    const response = err?.response;
    if (!response || !response.status) return false;
    // メンテナンス中の場合は送信しない
    // NOTE: ./constants.tsのMAINTENANCE_RESPONSEを使用したいが何故か使用できない
    // NOTE: response.data?.dataが"[Object]"と文字列になるため、response.data?.data?.scope === "chanto"を判定条件に使用できない
    if (response.status === 503 && response.data?.status === "maintenance") {
      return true;
    }

    const excludes = [422];
    return excludes.includes(response.status);
  };

  /**
   * フロントのエラーがAPIの戻りによるエラーかを判定し、
   * APIの戻りからの場合、拡張領域にAPI実行時のリクエストパラメーターなどをセットします。
   *
   * @param event
   * @returns
   */
  const setResponseData = (event) => {
    const { Error: err } = event.contexts || {};
    const config = err?.config;
    const response = err?.response;

    // APIのパスを取得
    const getPath = () => {
      if (!config || !config.url) return undefined;

      const arr = config.url.split("?");
      return arr.length < 1 ? undefined : arr[0];
    };

    // APIのリクエストパラメーター取得
    const getQuery = () => {
      if (!config || !config.url) return undefined;

      const request = err?.request;
      if (!request || !request.__sentry_xhr__) return undefined;

      const arr = request.__sentry_xhr__?.url?.split("?") || [];
      return arr.length < 2 ? undefined : arr[1].split("&");

      // こちらに変えるかもしれないので残しておく。
      // if (!config || !config.url) return undefined;
      // const arr = config.url?.split("?") || [];
      // let query = arr.length < 2 ? undefined : arr[1].split("&");
      // if (config.params) {
      //   const data = Object.entries(config.params).map(([key, value]) => {
      //     return `${key}=${value}`;
      //   });
      //   query = query ? Array.from(new Set([...query, ...data])) : data;
      // }
      // return query;
    };

    // トレースID取得
    const genTraceId = () => {
      if (!config.headers?.["X-Amzn-Trace-Id"]) return "";
      const traceId = config.headers?.["X-Amzn-Trace-Id"];
      return traceId.replace("Root=", "");
    };

    event.extra = {
      ...event.extra,
      ...{
        response: true,
        api_path: getPath(),
        query_string: getQuery(),
        message: response.data.message || "",
        trace_id: genTraceId() } };

    return event;
  };

  try {
    sendErrorLog(event);

    event = setScreenName(event);

    if (isResponse(event)) return !isCancel(event) ? setResponseData(event) : null;
  } catch {}

  return event;
},
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
  ]

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
